import React from 'react'
import './VulvaExternal.scss'

const VulvaExternal = ({selected, clickHandler}) => {

  const clickableImage = (kebabPart, camelPart) => {
    return (
      <button onClick={()=>clickHandler(camelPart)} >
        <img
          className={`${kebabPart} label ${!selected || selected === camelPart}`}
          src={`/assets/vulva-and-ovaries/LABEL-${kebabPart}.png`}
          alt={kebabPart}
        />
      </button>
    )
  }

  return (
    <div className='VulvaExternal'>
      <img className="background" src="/assets/vulva-and-ovaries/BACKGROUND_Vulva_External.jpg" alt="Vulva and ovaries" />
      {clickableImage("clitoris", "clitoris")}
      {clickableImage("vulva", "vulva")}
      {clickableImage("urethral-opening", "urethralOpening")}
      {clickableImage("vaginal-opening", "vaginalOpening")}
      {clickableImage("labia-minora", "labiaMinora")}
      {clickableImage("labia-majora", "labiaMajora")}
      {clickableImage("anus", "anus")}
    </div>
  )

}

export default VulvaExternal