import React from 'react'
import './VulvaInternal.scss'

const VulvaInternal = ({selected, clickHandler}) => {

  const clickableImage = (kebabPart, camelPart) => {
    return (
      <button onClick={()=>clickHandler(camelPart)} >
        <img
          className={`${kebabPart} label ${!selected || selected === camelPart}`}
          src={`/assets/vulva-and-ovaries/LABELS-${kebabPart}.png`}
          alt={kebabPart}
        />
      </button>
    )
  }

  return (
    <div className='VulvaInternal'>
          <img className="background" src="/assets/vulva-and-ovaries/BACKGROUND_F_Internal.jpg" alt="Vulva and ovaries" />

        {clickableImage("fallopian-tube", "fallopianTube")}
        {clickableImage("ovary", "ovary")}
        {clickableImage("uterus", "uterus")}
        {clickableImage("cervix", "cervix")}
        {clickableImage("vagina", "vagina")}

    </div>
  )

}

export default VulvaInternal